import React from 'react'
import classNames from 'classnames'
import Link from 'next/link'
import { CompositeSearchResult } from '../../types'
import parseItemPath from '../../utils/helpers/parseItemPath'
import { CompositeProduct } from 'src/types/schema/graphql'
import { Box, Center, HStack, Icon, Text, VStack } from '@chakra-ui/react'
import TankIcon from 'src/components/icons/TankIcon'
import { LocationMarkerIcon } from '@heroicons/react/outline'
import CiderIcon from 'src/components/icons/CiderIcon'
import CoffeeIcon from 'src/components/icons/CoffeeIcon'
import GlassIcon from 'src/components/icons/GlassIcon'
import KombuchaIcon from 'src/components/icons/KombuchaIcon'
import { determineSearchDisplayText } from '../../utils/helpers'

const LiveSearchResult: React.FC<{ item: CompositeSearchResult }> = ({ item }) => {
    const itemPath = parseItemPath(item) || ''
    if (
        item.__typename === 'Beer' ||
        item.__typename === 'Cider' ||
        item.__typename === 'Kombucha'
    ) {
        item = item as CompositeProduct
        if (item.isHiddenExternally === true) {
            return null
        }
    }

    const iconMap = {
        Brand: TankIcon,
        Beer: GlassIcon,
        Cider: CiderIcon,
        Coffee: CoffeeIcon,
        Kombucha: KombuchaIcon,
        Location: LocationMarkerIcon,
    }

    return (
        <div
            className={classNames(
                'border-t-none last:border-b-none w-full overflow-hidden border-b-px border-b-gray-100 border-t-transparent  transition-all duration-200 ease-in last:rounded-b-xl last:border-b-transparent'
            )}
        >
            <Link href={itemPath}>
                <a>
                    <Box bg='white' _hover={{ bg: 'gray.50' }}>
                        <HStack p={4} spacing={4}>
                            <Center w={12} h={12} bg='blue.50' color='blue.500' rounded='full'>
                                <Icon
                                    as={iconMap[item.__typename]}
                                    boxSize={8}
                                    placeSelf='center'
                                    color='blue.500'
                                />
                            </Center>
                            <VStack alignItems='flex-start' spacing={1}>
                                <Text as='b'>{item.title}</Text>
                                <Text fontSize='sm' color='gray.400'>
                                    {determineSearchDisplayText(item)}
                                </Text>
                            </VStack>
                        </HStack>
                    </Box>
                </a>
            </Link>
        </div>
    )
}

export default LiveSearchResult
