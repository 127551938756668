import React from 'react'

function KombuchaIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            fill='none'
            viewBox='0 0 24 24'
            {...props}
        >
            <g fill='currentColor'>
                <path d='M7.973 14.293a.5.5 0 01.353-.612l4.83-1.294a.5.5 0 01.612.354l.26.966a.5.5 0 01-.354.612l-4.83 1.294a.5.5 0 01-.612-.354zM10.232 15.74a.5.5 0 01.612-.353l4.83 1.294a.5.5 0 01.353.612l-.259.966a.5.5 0 01-.612.354l-4.83-1.294a.5.5 0 01-.353-.612z'></path>
                <path
                    fillRule='evenodd'
                    d='M7 3.5A1.5 1.5 0 018.5 2h7A1.5 1.5 0 0117 3.5v2c0 .231-.052.45-.146.646l3.234 1.94c1.48.888.534 3.126-1.088 2.754V19a3 3 0 01-3 3H8a3 3 0 01-3-3v-8.156l-.313.094c-1.664.5-2.704-1.759-1.242-2.699L7.06 5.916A1.501 1.501 0 017 5.5zM9 5h6V4H9zm-2 6.118l.33.165a1.5 1.5 0 001.34 0l1.33-.665 1.33.665a1.5 1.5 0 001.34 0l1.33-.665 1.33.665a1.5 1.5 0 001.34 0l.33-.165V19a1 1 0 01-1 1H8a1 1 0 01-1-1zm10.287-2.38L15.223 7.5h-6.93l-1.78 1.144c.038.015.075.032.112.05L8 9.382l1.33-.665a1.5 1.5 0 011.34 0l1.33.665 1.33-.665a1.5 1.5 0 011.34 0l1.33.665z'
                    clipRule='evenodd'
                ></path>
            </g>
        </svg>
    )
}

export default KombuchaIcon
