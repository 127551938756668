import { Box, Button, Heading, Icon } from '@brewlogix/blx-ui'
import { NextPage } from 'next'
import Image from 'next/image'
import Link from 'next/link'
import { Columns, Cta, CuratedBoardsPreview, EntryPoints, Hero } from 'src/components/home'
import { useFilterStore } from 'src/features/Map/state/filterStore'
import * as PageSection from 'src/features/pageSections/components'
import homePageSectionsConfig from 'src/features/pageSections/config/homePageSectionsConfig'
import { CompositeSearchResult } from 'src/features/search/types'
import { getNearbyLocations, parseUserLocationHeaders } from 'src/features/search/utils/helpers'
import SEOTags from 'src/features/seo/components/SEOTags'
import StructuredData from 'src/features/seo/components/StructuredData'
import { FoodOption, PatronOption, SeatingOption } from 'src/types/schema/graphql'

interface HomePageProps {
    userLocation: string
    nearbyResults: CompositeSearchResult[]
}

const Page: NextPage<HomePageProps> = ({ userLocation, nearbyResults }) => {
    const { setPatronFilterOptions, setFoodFilterOptions, setSeatingFilterOptions } =
        useFilterStore()

    return (
        <>
            <SEOTags schemaType='home' />
            <StructuredData schemaType='home' />
            <div className='bg-no-repeat md:bg-cover' data-location={userLocation}>
                {nearbyResults && <Hero nearbyResults={nearbyResults} />}
                <CuratedBoardsPreview />
                <EntryPoints
                    title='Drink. Eat. Enjoy.'
                    subtitle='What are you in the mood for today?'
                >
                    <>
                        <div className='row-span-2 mb-12 grid h-[60rem] grid-cols-12 gap-4 px-4 md:mb-0 md:h-[40rem] md:grid-rows-2 md:gap-8'>
                            <div className='relative col-span-12 overflow-hidden rounded-lg md:col-span-6 md:row-span-2'>
                                <Link href='/map/?option=breweries-for-you' passHref>
                                    <a
                                        onClick={() =>
                                            setPatronFilterOptions([PatronOption.FamilyFriendly])
                                        }
                                        className='block h-full'
                                    >
                                        <Box customClasses='transform transition duration-500 hover:scale-105 bg-[url(/assets/BDB-homepage-family-friendly.jpg)] bg-center bg-gray-200 bg-blend-multiply bg-cover rounded-lg cursor-pointer w-full h-full pt-24 pb-16 md:py-0' />
                                        <div className='absolute bottom-6 left-6 w-fit'>
                                            <Icon theme='light' slug='HeroIcons.UserGroup' />
                                            <Heading
                                                text='Family Friendly'
                                                type='h3'
                                                size='h4'
                                                theme='light'
                                                customClasses='text-base md:text-2xl'
                                            />
                                        </div>
                                    </a>
                                </Link>
                            </div>
                            <div className='relative col-span-12 overflow-hidden rounded-lg md:col-span-6 md:row-span-1'>
                                <Link href='/map/?option=breweries-for-you' passHref>
                                    <a
                                        className='block h-full'
                                        onClick={() => setPatronFilterOptions([PatronOption.Dogs])}
                                    >
                                        <Box customClasses='transform transition duration-300 hover:scale-105 bg-[url(/assets/BDB-homepage-dog-friendly.jpg)] bg-gray-200 bg-blend-multiply bg-cover bg-center rounded-lg cursor-pointer w-full h-full pt-24 pb-16 md:py-0' />
                                        <div className='absolute bottom-6 left-6 w-fit'>
                                            <Image
                                                src='/assets/pets-icon.svg'
                                                width={24}
                                                height={24}
                                                alt='Dog paw print icon'
                                            />

                                            <Heading
                                                text='Dog Friendly'
                                                type='h3'
                                                size='h4'
                                                theme='light'
                                                customClasses='text-base md:text-2xl'
                                            />
                                        </div>
                                    </a>
                                </Link>
                            </div>
                            <div className='relative col-span-12 overflow-hidden rounded-lg md:col-span-3 md:row-span-1'>
                                <Link href='/map/?option=breweries-for-you' passHref>
                                    <a
                                        onClick={() =>
                                            setFoodFilterOptions([FoodOption.GlutenFree])
                                        }
                                        className='block h-full'
                                    >
                                        <Box customClasses='transform transition duration-300 hover:scale-105 bg-[url(/assets/BDB-homepage-gluten-free.jpg)] bg-gray-200 bg-blend-multiply bg-cover bg-center rounded-lg cursor-pointer w-full h-full pt-24 pb-16 md:py-0' />
                                        <div className='absolute bottom-6 left-6 w-fit'>
                                            <Image
                                                src='/assets/gluten-free-icon.svg'
                                                width={24}
                                                height={24}
                                                alt='Gluten icon crossed out'
                                            />
                                            <Heading
                                                text='Gluten Free'
                                                type='h3'
                                                size='h4'
                                                theme='light'
                                                customClasses='text-base md:text-2xl'
                                            />
                                        </div>
                                    </a>
                                </Link>
                            </div>
                            <div className='relative col-span-12 overflow-hidden rounded-lg md:col-span-3 md:row-span-1'>
                                <Link href='/map/?option=breweries-for-you' passHref>
                                    <a
                                        onClick={() =>
                                            setSeatingFilterOptions([SeatingOption.Outdoor])
                                        }
                                        className='block h-full'
                                    >
                                        <Box customClasses='transform transition duration-300 hover:scale-105 bg-[url(/assets/BDB-outdoor-seating.jpg)] bg-gray-200 bg-blend-multiply blend-mode-overlay bg-cover rounded-lg cursor-pointer w-full h-full pt-24 pb-16 md:py-0' />
                                        <div className='absolute bottom-6 left-6 w-fit'>
                                            <Image
                                                src='/assets/seat-icon-new.svg'
                                                width={24}
                                                height={24}
                                                alt='Seat with armrests icon'
                                            />
                                            <Heading
                                                text='Outdoor Seating'
                                                type='h3'
                                                size='h4'
                                                theme='light'
                                                customClasses='text-base md:text-2xl'
                                            />
                                        </div>
                                    </a>
                                </Link>
                            </div>
                        </div>
                        <div className='flex justify-center md:pb-10 md:pt-24'>
                            <Button
                                label='Explore the Map'
                                type='a'
                                href='/map'
                                icon='HeroIconsSolid.Map'
                                iconAlignment='left'
                                size='md'
                            />
                        </div>
                    </>
                </EntryPoints>
                <Columns
                    title='Great Experiences are Just Around the Corner'
                    subtitle='Let us help you find yours'
                />

                <PageSection.MediaCTA {...homePageSectionsConfig[0]} />

                <Cta
                    title='Ready to Tap Into BreweryDB?'
                    subtitle='Start crafting your own brewery experiences today!'
                >
                    <Button
                        label='Create an account'
                        theme='primary'
                        icon='HeroIcons.ChevronRight'
                        customClasses='mb-32'
                        type='a'
                        href='/login?action=register'
                    />
                </Cta>
            </div>
        </>
    )
}

export default Page

export async function getServerSideProps({ req }) {
    const city = req.headers && req.headers['x-vercel-ip-city']
    const state = req.headers && req.headers['x-vercel-ip-country-region']
    const coords: [number, number] =
        req.headers['x-vercel-ip-latitude'] && req.headers['x-vercel-ip-longitude']
            ? [req.headers['x-vercel-ip-latitude'], req.headers['x-vercel-ip-longitude']]
            : null

    const userLocationParsed = await parseUserLocationHeaders({
        city,
        state,
        coords,
    })

    const initialResults =
        userLocationParsed.coords?.length === 2 &&
        (await getNearbyLocations(userLocationParsed.coords[0], userLocationParsed.coords[1], 25))

    return {
        props: {
            userLocation: userLocationParsed?.address,
            nearbyResults: initialResults || [],
        },
    }
}
