import React from 'react'
import { Heading } from '@brewlogix/blx-ui'

interface EntryPointsProps {
    title: string
    subtitle?: React.ReactNode
    children: React.ReactNode
}

const EntryPoints: React.FC<EntryPointsProps> = ({ title, subtitle, children }) => {
    return (
        <section className='lg:mt-32 lg:mb-16 my-16'>
            <div className='container flex flex-col w-full mx-auto'>
                <div className='md:mb-20 mb-8'>
                    {title && (
                        <Heading
                            text={title}
                            data-cy='entry-title'
                            type='h2'
                            size='h3'
                            theme='dark'
                            customClasses='flex justify-center mb-4 font-extrabold font-lato text-2xl md:text-4xl'
                        />
                    )}
                    {subtitle && (
                        <p className='font-lato md:text-xl flex justify-center w-full text-lg font-medium text-gray-400'>
                            {subtitle}
                        </p>
                    )}
                </div>
                {children}
            </div>
        </section>
    )
}

export default EntryPoints
