import { Button, Heading, SimpleGrid, Badge, HStack, Text, Box } from '@chakra-ui/react'
import React from 'react'
import BoardPreviewCard from 'src/features/saved/components/BoardPreviewCard/BoardPreviewCard'
import {
    generateCuratedBoardLink,
    parseBDBEnvSubdomain,
} from 'src/features/favorites/utils/helpers'
import useCuratedBoards from 'src/hooks/useCuratedBoards'

const CuratedBoardsPreview: React.FC = () => {
    const [envSubdomain] = React.useState(() =>
        parseBDBEnvSubdomain(typeof window !== 'undefined' ? window.location.hostname : '')
    )
    const { curatedBoards } = useCuratedBoards(envSubdomain)

    return (
        <section className='md:mb-16'>
            <div className='flex h-full w-full flex-col'>
                <div className='mb-8 mt-12 md:my-12'>
                    <HStack gap={3} align='center' justify='center' mb={4}>
                        <Heading className='font-lato flex justify-center text-center text-2xl font-extrabold md:text-4xl'>
                            Curated Boards
                        </Heading>
                        <Badge fontSize='md' className='rounded bg-purple-50 text-purple-500'>
                            NEW
                        </Badge>
                    </HStack>
                    <Text
                        fontSize={{ base: 'lg', md: 'xl' }}
                        color='gray.400'
                        px={6}
                        align='center'
                    >
                        Hand Crafted Adventures curated for you by industry experts
                    </Text>
                </div>
                <div className='mx-auto mb-12 flex w-full max-w-screen-xl justify-center px-4 md:px-8'>
                    <SimpleGrid spacing={{ base: 8, lg: 14 }} columns={{ sm: 1, lg: 3 }} w='full'>
                        {curatedBoards.slice(0, 3).map((board) => {
                            if (!board) return
                            return (
                                <BoardPreviewCard
                                    key={board.id}
                                    board={board}
                                    boardTitle={board.title}
                                    routesLength={board.routes.length}
                                    favorites={board.favorites}
                                    boardLink={generateCuratedBoardLink(board.id, envSubdomain)}
                                />
                            )
                        })}
                    </SimpleGrid>
                </div>
                <div className='flex justify-center'>
                    <Button as='a' href='/curated-boards'>
                        See all Curated Boards
                    </Button>
                </div>
            </div>
        </section>
    )
}

export default CuratedBoardsPreview
