import { useState, useEffect, useMemo } from 'react'
import getCuratedBoardIdList from 'src/features/favorites/utils/helpers/getCuratedBoardIdList'
import { useSavedBoardsStore } from 'src/features/favorites/state/useSavedBoardsStore'

function useCuratedBoards(envSubdomain: string) {
    const [curatedBoards, setCuratedBoards] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    const getSharedBoard = useSavedBoardsStore((state) => state.getSharedBoard)
    const curatedBoardsIdList = useMemo(() => {
        return getCuratedBoardIdList(envSubdomain)
    }, [])

    const fetchBoards = async () => {
        try {
            const boards = await Promise.all(
                curatedBoardsIdList.map(async ({ boardId, userId }) => {
                    const board = await getSharedBoard(boardId, userId)
                    return board
                })
            )

            setCuratedBoards(boards)
            setLoading(false)
        } catch (e) {
            setError(e)
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchBoards()
    }, [])

    return { curatedBoards, loading, error }
}

export default useCuratedBoards
