import React from 'react'

function TankIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17.452 38.199' {...props}>
            <path
                d='M17.397 3.767A4.47 4.47 0 0 0 12.989 0H4.464A4.47 4.47 0 0 0 .056 3.767H.001v33.735a.7.7 0 0 0 .7.7.7.7 0 0 0 .7-.7v-8a4.248 4.248 0 0 0 4.208 3.7h.174v.383A2.218 2.218 0 0 0 7.998 35.8H9.62a2.218 2.218 0 0 0 2.215-2.215v-.383h.022a4.249 4.249 0 0 0 4.208-3.7v8a.7.7 0 0 0 .7.7.7.7 0 0 0 .7-.7V3.767Zm-6.963 29.818a.823.823 0 0 1-.821.822H7.991a.822.822 0 0 1-.822-.822v-.225h3.265ZM4.463 1.394h8.525a3.075 3.075 0 0 1 2.99 2.373h-14.5a3.075 3.075 0 0 1 2.985-2.373ZM14.7 28.959a2.853 2.853 0 0 1-2.85 2.85H5.604a2.853 2.853 0 0 1-2.85-2.85v-.762h11.948Zm1.358-2.173H1.393V5.161h14.665Z'
                fill='currentColor'
            />
            <path
                d='M8.723 24.782a3.777 3.777 0 0 0 3.772-3.772 3.777 3.777 0 0 0-3.772-3.772 3.776 3.776 0 0 0-3.772 3.772 3.776 3.776 0 0 0 3.772 3.772Zm0-6.15a2.381 2.381 0 0 1 2.379 2.378 2.381 2.381 0 0 1-2.379 2.379 2.381 2.381 0 0 1-2.378-2.379 2.381 2.381 0 0 1 2.378-2.378Z'
                fill='currentColor'
            />
        </svg>
    )
}

export default TankIcon
