import React from 'react'

function GlassIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15 22' key='glass' {...props}>
            <path
                fill='currentColor'
                fillRule='evenodd'
                d='M.058 2.698A1.525 1.525 0 011.57.975h11.86c.921 0 1.632.81 1.512 1.723l-2.225 17a1.524 1.524 0 01-1.511 1.327H3.794c-.768 0-1.412-.57-1.511-1.327l-2.225-17zm2.11.327l.395 3.019c.359-.305.777-.629 1.217-.902.445-.278.975-.543 1.527-.649 1.19-.228 2.05.434 2.549.819l.01.008c.607.467.854.645 1.168.656.536.017 1.34-.254 2.128-.63a13.186 13.186 0 001.261-.694l.016-.01.002-.001c.062-.04.127-.073.192-.098l.2-1.518H2.167zm10.135 4.044l-1.1 8.406H3.797L2.89 8.549a13.933 13.933 0 01.964-.912c.32-.275.668-.543 1.008-.755.35-.218.633-.338.83-.375.198-.038.375.015.922.437l.116.09c.458.36 1.218.957 2.235.99 1.073.036 2.27-.442 3.08-.829.088-.042.174-.084.257-.126zM4.066 17.525l.19 1.45h6.488l.19-1.45H4.066z'
                clipRule='evenodd'
            ></path>
        </svg>
    )
}

export default GlassIcon
