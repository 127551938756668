import React, { useRef, useCallback } from 'react'
import { useRouter } from 'next/router'
import { CompositeSearchResult } from '../types'
import liveSearchConfig from '../config/liveSearchConfig'

import useOutsideClick from 'src/hooks/useOutsideClick'
import { debounce } from 'lodash'
import { useSearch } from '../state/useSearch'
import SearchTabs from './SearchTabs'
import SearchForm from './SearchForm'
import LiveSearchResults from './results/LiveSearchResults'
import determineTypeFilters from '../utils/helpers/determineTypeFilters'

export const LiveSearch: React.FC<{ initialResults: CompositeSearchResult[] }> = ({
    initialResults,
}) => {
    const router = useRouter()
    const searchBoxRef = useRef()
    const { activeTab, searchTerm, getResults, setShowResults, setIsSearching, resetSearchStore } =
        useSearch()

    useOutsideClick(
        searchBoxRef,
        useCallback(() => {
            setShowResults(false)
        }, [])
    )
    React.useEffect(() => {
        setIsSearching(false)
        return () => resetSearchStore()
    }, [])

    React.useEffect(() => {
        if (searchTerm?.length > 2) debouncedSearch()
    }, [searchTerm, activeTab])

    const debouncedSearch = React.useMemo(
        () =>
            debounce(async () => {
                await getResults({
                    limit: liveSearchConfig?.limit * 5,
                    search: {
                        typeFilters: determineTypeFilters(activeTab),
                    },
                })
            }, 300),
        [activeTab]
    )

    const handleSearch = (e: React.FormEvent) => {
        e.preventDefault()

        if (searchTerm.length > 0) {
            router.push({
                pathname: '/search',
                query: { searchTerm: searchTerm, type: activeTab },
            })
        }
    }

    return (
        <div
            className='relative h-auto w-full rounded-xl rounded-t-none border-px border-gray-100 bg-white lg:rounded-t-xl'
            ref={searchBoxRef}
        >
            <div className=''>
                {liveSearchConfig.tabs && <SearchTabs />}
                <SearchForm
                    config={liveSearchConfig}
                    onSubmit={handleSearch}
                    customClasses='py-2 px-3 md:py-3'
                />

                <LiveSearchResults initialResults={initialResults} />
            </div>
        </div>
    )
}

export default LiveSearch
