import React from 'react'

function CoffeeIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            fill='none'
            viewBox='0 0 24 24'
            {...props}
        >
            <g fill='currentColor'>
                <g fillRule='evenodd' clipRule='evenodd'>
                    <path d='M4 21a1 1 0 011-1h14a1 1 0 110 2H5a1 1 0 01-1-1z'></path>
                    <path d='M5.007 11.383A1 1 0 016 10.5h12a1 1 0 01.993.883L18 11.5l.993-.117v.005l.002.008.002.025.008.085a13.38 13.38 0 01.041 1.342c-.016.85-.116 2.008-.462 3.184-.345 1.175-.953 2.425-2.027 3.385C15.465 20.395 13.97 21 12 21c-1.97 0-3.465-.605-4.557-1.583-1.074-.96-1.682-2.21-2.027-3.385-.346-1.176-.446-2.334-.463-3.184a13.406 13.406 0 01.042-1.342l.008-.085.002-.025.001-.008v-.005L6 11.5zM6.95 12.5c0 .097 0 .2.002.308.015.744.102 1.711.381 2.66.28.95.734 1.825 1.442 2.459C9.465 18.543 10.47 19 12 19s2.535-.457 3.224-1.073c.708-.634 1.162-1.508 1.442-2.46.279-.948.367-1.915.381-2.659.002-.108.003-.211.002-.308z'></path>
                    <path d='M16.32 18.238a3.5 3.5 0 101.718-6.208c.02.473.024 1.22-.08 2.07a1.5 1.5 0 11-.588 2.386 6.093 6.093 0 01-1.05 1.752z'></path>
                </g>
                <path d='M6.708 3.404l.003-.003.001-.002a1 1 0 011.6 1.201l-.008.01a2.646 2.646 0 00-.155.238c-.045.075-.085.15-.114.217l-.01.023a3.538 3.538 0 00.234.245c.25.242.898.874.739 1.831-.068.41-.277.794-.403 1.007a5.326 5.326 0 01-.254.39l-.02.026-.007.009-.002.003-.001.002a1 1 0 01-1.6-1.2V7.4l.008-.01a2.636 2.636 0 00.155-.238c.044-.075.084-.15.114-.218l.01-.022a3.538 3.538 0 00-.234-.245c-.25-.242-.899-.874-.739-1.831.068-.41.277-.794.403-1.007a5.32 5.32 0 01.254-.39l.02-.026zM14.708 3.404l.002-.003.002-.002a1 1 0 011.6 1.201l-.009.01a2.751 2.751 0 00-.154.238 2.15 2.15 0 00-.114.217l-.01.023a3.535 3.535 0 00.234.245c.25.242.898.874.739 1.831-.068.41-.277.794-.403 1.007a5.315 5.315 0 01-.254.39l-.02.026-.006.009-.003.003-.001.002a1 1 0 01-1.6-1.2V7.4l.008-.01a2.751 2.751 0 00.155-.238c.044-.075.084-.15.114-.218l.01-.022a3.535 3.535 0 00-.234-.245c-.25-.242-.899-.874-.74-1.831.07-.41.278-.794.404-1.007a5.365 5.365 0 01.254-.39l.02-.026zM10.708 2.404l.002-.003.002-.002a1 1 0 011.6 1.201l-.008.01a2.751 2.751 0 00-.155.238 2.15 2.15 0 00-.114.217l-.01.023a3.535 3.535 0 00.234.245c.25.242.898.874.739 1.831-.068.41-.277.794-.403 1.007a5.315 5.315 0 01-.254.39l-.02.026-.006.009-.003.003-.001.002a1 1 0 01-1.6-1.2V6.4l.008-.01a2.751 2.751 0 00.155-.238c.044-.075.084-.15.114-.218l.01-.022a3.535 3.535 0 00-.234-.245c-.25-.242-.899-.874-.74-1.831.07-.41.278-.794.404-1.007a5.365 5.365 0 01.254-.39l.02-.026z'></path>
            </g>
        </svg>
    )
}

export default CoffeeIcon
