import { BookmarkIcon } from '@heroicons/react/solid'
import Link from 'next/link'
import React from 'react'
import { CardImage } from 'src/features/account/components/favorites'
import { Asset, Board, BreweryRoute, Favorites } from 'src/types/schema/graphql'
import { getBoardAssets } from '../../helpers/getBoardAssets'
import LargeGridSquare from './LargeGridSquare'
import { Badge, Box, Grid, GridItem, HStack, Icon, Text, VStack } from '@chakra-ui/react'

interface IBoardsCard {
    board: Board
    boardTitle: string
    routesLength: number
    favorites: Favorites
    boardLink: string
    route?: BreweryRoute
    boardBadge?: string
}

const BoardPreviewCard: React.FC<IBoardsCard> = ({
    board,
    boardTitle,
    routesLength,
    favorites,
    boardLink,
    route,
    boardBadge,
}) => {
    const [allAssets, setAllAssets] = React.useState<Asset[]>([])

    // As we need different badges for different boards, we can add to this function
    function renderBadge(boardBadge?: string) {
        switch (boardBadge) {
            case 'bookmarked':
                return (
                    <Badge
                        fontSize='xs'
                        className='rounded'
                        backgroundColor={'teal.100'}
                        textColor={'teal.600'}
                        display='flex'
                        alignItems='center'
                        pointerEvents='none'
                    >
                        <Icon as={BookmarkIcon} />
                        <Text>Bookmarked</Text>
                    </Badge>
                )
            default:
                return null
        }
    }

    React.useEffect(() => {
        ;(async () => {
            const { productAssets, locationAssets, brandAssets } = await getBoardAssets(
                board?.favorites,
                board?.title
            )
            const displayAssets = [
                locationAssets[0],
                productAssets[0],
                productAssets[1],
                productAssets[2],
                locationAssets[1],
                locationAssets[2],
                brandAssets[0],
                brandAssets[1],
                brandAssets[2],
            ]
            const filtered = displayAssets.filter((item) => item !== undefined)
            setAllAssets(filtered)
        })()
    }, [])
    const startIndex = route ? 0 : 1
    const endIndex = route ? 2 : 3
    const totalLength = allAssets?.slice(startIndex, endIndex)?.length
    const totalBrewsCount = favorites?.favoriteProducts?.filter((item) => item?.isActive)?.length
    const totalBrandLocationCount =
        favorites?.favoriteLocations?.filter((item) => item?.isActive)?.length +
        favorites?.favoriteBrands?.filter((item) => item?.isActive)?.length
    return (
        <Box>
            <Link href={boardLink}>
                <a>
                    <Grid
                        h={'auto'}
                        templateColumns={{ sm: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)' }}
                        gap={3}
                    >
                        <GridItem
                            rowSpan={2}
                            colSpan={2}
                            bg='gray.100'
                            rounded={'xl'}
                            minWidth='9rem'
                            minHeight={'15.25rem'}
                        >
                            <LargeGridSquare route={route} assets={allAssets} />
                        </GridItem>
                        {allAssets?.slice(startIndex, endIndex)?.map((item) => (
                            <GridItem
                                key={item?.path}
                                colSpan={1}
                                bg='gray.100'
                                rounded={'xl'}
                                minWidth={'7.25rem'}
                                aspectRatio={'1/1'}
                            >
                                <CardImage
                                    customClasses='overflow-hidden rounded-xl object-contain'
                                    asset={item}
                                    alt={item?.alt}
                                />
                            </GridItem>
                        ))}
                        {totalLength < 2 && (
                            <GridItem
                                colSpan={1}
                                bg='gray.100'
                                rounded={'xl'}
                                minWidth={'7.25rem'}
                                aspectRatio={'1/1'}
                            ></GridItem>
                        )}
                        {totalLength === 0 && (
                            <GridItem
                                colSpan={1}
                                bg='gray.100'
                                rounded={'xl'}
                                minWidth={'7.25rem'}
                                aspectRatio={'1/1'}
                            ></GridItem>
                        )}
                    </Grid>
                    <VStack spacing={'sm'} mt={4} align='stretch'>
                        <HStack>
                            <Text fontSize='md' as='b' color='dark'>
                                {boardTitle}
                            </Text>
                            {boardBadge && renderBadge(boardBadge)}
                            {/* @TODO: Save for when edit boards is ready */}
                            {/* <Box>
                                <Icon boxSize={'1.5rem'} as={HeroIcons.DotsHorizontalIcon} />
                            </Box> */}
                        </HStack>
                        <HStack spacing={2}>
                            <Text fontSize='sm' color='dark'>
                                {routesLength} Routes
                            </Text>
                            <span>·</span>
                            <Text fontSize='sm' color='dark'>
                                {totalBrandLocationCount}{' '}
                                {`Brewer${totalBrandLocationCount === 1 ? 'y' : 'ies'}`}
                            </Text>
                            <span>·</span>
                            <Text fontSize='sm' color='dark'>
                                {totalBrewsCount} Brews
                            </Text>
                        </HStack>
                    </VStack>
                </a>
            </Link>
            {board?.description && (
                <VStack
                    className='mt-2 bg-gray-50'
                    resize='none'
                    fontSize={'sm'}
                    alignItems='flex-start'
                    rounded='xl'
                    p='4'
                    w='full'
                >
                    <div className='w-full'>{board?.description}</div>
                </VStack>
            )}
        </Box>
    )
}

export default React.memo(BoardPreviewCard)
