import AppStoreButton from 'src/components/buttons/AppStoreButton'
import { PageSectionConfig } from 'src/features/pageSections/types'
import { PageSections } from 'src/features/pageSections/types/enums'
import IconThemes from 'src/features/pageSections/types/enums/IconThemes'
import { AppStoreOptions } from 'src/types/ui/enums'
import * as CC from '@chakra-ui/react'
import * as HeroIcons from '@heroicons/react/solid'
import Link from 'next/link'

const homePageSectionsConfig: PageSectionConfig[] = [
    {
        id: 'mobile-app-promotion',
        component: PageSections.MediaCTA,
        title: (
            <>
                Find Your&nbsp;
                <strong>New Go-To Spot</strong>
                {' with the '}
                <strong>BreweryDB App</strong>
            </>
        ),
        items: null,
        subtitle: null,
        body: (
            <>
                <p className='pr-2 text-xl text-gray-400 md:pr-6'>
                    Discover new brews, find places to try them, optimize your BreweryRoute, and
                    save it all on a custom board that you curate.
                </p>
                <div className='flex w-full items-start justify-center gap-4 md:justify-start'>
                    <AppStoreButton appStore={AppStoreOptions.AppStore} />
                    <AppStoreButton appStore={AppStoreOptions.GooglePlay} />
                </div>
                <Link href='/download'>
                    <a className=' flex items-center text-left font-semibold'>
                        <p className='mr-1 text-info'>Read more about BreweryDB app</p>
                        <CC.Icon as={HeroIcons.ArrowRightIcon} className='h-4 w-4 text-info' />
                    </a>
                </Link>
            </>
        ),
        media: {
            path: '/assets/download/bdb-mobile--hero-image.png',
            alt: 'mobile preview',
            width: 449,
            height: 692,
        },
        alignment: 'left',
    },
]

export default homePageSectionsConfig
