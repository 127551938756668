import React from 'react'

function CiderIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            fill='none'
            viewBox='0 0 24 24'
            {...props}
        >
            <g fill='currentColor' fillRule='evenodd' clipRule='evenodd'>
                <path d='M15.46 14.885c.301-.45.825-.885 1.54-.885s1.239.434 1.54.885c.304.457.46 1.03.46 1.615s-.156 1.158-.46 1.615c-.301.45-.825.885-1.54.885s-1.239-.434-1.54-.885c-.304-.457-.46-1.03-.46-1.615s.156-1.158.46-1.615z'></path>
                <path d='M15 2a1 1 0 00-.503 1.864c-.005.259-.015.56-.032.881-.054.999-.177 2.054-.404 2.676-.156.426-.42.834-.753 1.35-.108.168-.224.348-.347.544-.175.28-.364.597-.528.944.486.072.959.239 1.397.55.646.458.992 1.239 1.116 2.191H20v6a1 1 0 01-1 1h-4a1 1 0 01-1-1v-.23a16.315 16.315 0 01-1.09 2.383A2.99 2.99 0 0015 22h4a3 3 0 003-3v-7c0-1.058-.524-1.985-.961-2.685a40.606 40.606 0 00-.348-.544c-.333-.516-.596-.924-.752-1.35-.227-.622-.35-1.677-.404-2.676a26.45 26.45 0 01-.032-.88A1 1 0 0019 2zm1.462 2.853c.016-.308.027-.598.033-.853h1.01c.007.255.017.545.033.853.031.574.087 1.257.194 1.912h-1.463c.106-.655.162-1.338.193-1.912zm-.818 3.912h2.712c.226.434.498.853.736 1.22.09.138.175.268.25.39.143.228.263.435.36.625h-5.404c.097-.19.217-.397.36-.625.075-.122.16-.252.25-.39.238-.367.51-.786.736-1.22z'></path>
                <path d='M6.077 9.293a1 1 0 011.414 0c.261.261.447.623.57.895.138.302.26.635.36.933l.084.261.103-.022c.265-.058.585-.129.906-.178.792-.121 1.867-.165 2.832.49.945.643 1.213 1.748 1.237 2.698.026.983-.196 2.068-.512 3.046-.318.983-.755 1.93-1.216 2.643a4.712 4.712 0 01-.77.941c-.238.215-.702.56-1.301.494a4.36 4.36 0 01-.599-.126 29.08 29.08 0 01-.471-.132l-.147-.042c-.209-.06-.398-.112-.558-.149-.13-.03-.198-.04-.22-.044h-.003c-.015.002-.068.01-.172.037-.133.034-.29.082-.47.14l-.146.048c-.131.043-.27.088-.396.126-.165.05-.375.11-.566.138-.595.085-1.072-.233-1.334-.45-.3-.249-.575-.581-.818-.933-.49-.711-.958-1.66-1.303-2.646-.342-.98-.589-2.07-.58-3.06.007-.96.264-2.078 1.221-2.728.96-.653 1.981-.614 2.764-.49.117.019.236.04.353.064a5.419 5.419 0 00-.098-.232c-.088-.192-.146-.28-.161-.303l-.003-.005a1 1 0 010-1.414zm2.772 4.061c-.31.067-.7.146-1.065.146-.404 0-.806-.074-1.142-.144a36.05 36.05 0 01-.312-.068c-.23-.05-.437-.095-.657-.13-.602-.095-.995-.056-1.327.169-.146.1-.34.379-.346 1.09-.005.68.17 1.53.47 2.385.296.85.689 1.631 1.06 2.168.164.238.304.398.407.493l.082-.024.34-.109.172-.055c.184-.06.39-.124.586-.174.177-.046.425-.101.667-.101.236 0 .494.054.68.098a14.656 14.656 0 01.813.219c.154.044.295.084.424.118l.1.027c.097-.1.225-.259.374-.488.35-.542.718-1.325.993-2.174.276-.853.433-1.7.416-2.379-.018-.71-.214-.993-.362-1.094-.327-.222-.764-.266-1.405-.168a11.93 11.93 0 00-.752.148l-.216.047zm.837 6.207s.006-.005.018-.011c-.012.009-.018.011-.018.011zm-3.641-.013l-.016-.01.016.01z'></path>
            </g>
        </svg>
    )
}

export default CiderIcon
