import React from 'react'
import { Box, Heading } from '@brewlogix/blx-ui'

interface CtaProps {
    title?: string
    subtitle?: React.ReactNode
    children: React.ReactNode
}

const Cta: React.FC<CtaProps> = ({ title, subtitle, children }) => {
    return (
        <section className='cta-section md:py-12 relative w-full px-4 py-8'>
            <div className='container flex flex-row items-center justify-center w-full mx-auto'>
                <Box customClasses='relative bg-[url(/assets/x-logo-white.svg)] bg-green-100  bg-cover w-full rounded-lg flex flex-col items-center'>
                    {title && (
                        <Heading
                            text={title}
                            size='h3'
                            customClasses='text-green-700 flex justify-center text-center font-lato font-extrabold mt-32 mb-4'
                        />
                    )}
                    {subtitle && (
                        <p className='flex justify-center mb-6 font-semibold text-center text-green-500'>
                            {subtitle}
                        </p>
                    )}
                    {children}
                </Box>
            </div>
        </section>
    )
}

export default Cta
