import React from 'react'
import * as UI from '@brewlogix/blx-ui'

import { useSearch } from '../../state/useSearch'
import LiveSearchResult from './LiveSearchResult'
import { CompositeSearchResult } from '../../types'
import liveSearchConfig from '../../config/liveSearchConfig'
import LiveSearchNoResults from '../cards/LiveSearchNoResults'
import LiveSearchError from '../cards/LiveSearchError'
import classNames from 'classnames'
import filterResultsByTab from '../../utils/helpers/filterResultsByTab'

const LiveSearchResults: React.FC<{ initialResults: CompositeSearchResult[] }> = ({
    initialResults,
}) => {
    const { apiData, searchTerm, isSearching, error, results, showResults, activeTab } = useSearch()

    const [searchResults, setSearchResults] =
        React.useState<CompositeSearchResult[]>(initialResults)

    React.useEffect(() => {
        const filteredInitialResults = filterResultsByTab(initialResults, activeTab)
        setSearchResults(
            results?.length > 0
                ? results?.slice(0, liveSearchConfig.limit)
                : !searchTerm
                ? filteredInitialResults.slice(0, liveSearchConfig.limit)
                : []
        )
    }, [results, activeTab])

    return (
        <div
            className={classNames(
                'h-auto w-full overflow-hidden border-t-2 border-gray-50 transition-all duration-200 ease-in',
                showResults ? 'max-h-[36rem] opacity-100' : 'max-h-0 opacity-0'
            )}
        >
            <div>
                <div className='relative flex w-full flex-col items-start'>
                    <div
                        className={classNames(
                            'pointer-events-none absolute top-0 bottom-0 left-0 right-0 z-20 flex w-full flex-col justify-center bg-light bg-opacity-80 text-center transition-all duration-200',
                            isSearching ? 'opacity-100' : 'opacity-0'
                        )}
                    >
                        <UI.Loading />
                    </div>

                    {searchResults?.map((item, index) => (
                        <LiveSearchResult item={item} key={`${index}-${item?.id}`} />
                    ))}

                    {searchTerm && results.length >= liveSearchConfig.limit && (
                        <div className='last:border-b-none py-3'>
                            <UI.Button
                                type='submit'
                                form='search-form'
                                theme='link'
                                label={liveSearchConfig.moreResultsLabel}
                                icon='HeroIcons.ChevronRight'
                                customClasses='flex w-full items-center justify-center text-dark hover:bg-white hover:text-info'
                            />
                        </div>
                    )}

                    {searchResults.length < 1 && searchTerm && <LiveSearchNoResults />}

                    {error && <LiveSearchError />}
                </div>
            </div>
        </div>
    )
}

export default LiveSearchResults
