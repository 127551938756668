import React from 'react'
import { Heading, Icon, Pill } from '@brewlogix/blx-ui'
import Link from 'next/link'

const Columns: React.FC<{ title: string; subtitle: string }> = ({ title, subtitle }) => {
    return (
        <section className='px-4 md:mb-16'>
            <div className='container mx-auto flex w-full flex-col'>
                <div className='mb-12 md:mb-20'>
                    {title && (
                        <Heading
                            text={title}
                            type='h2'
                            size='h3'
                            theme='dark'
                            customClasses='flex justify-center text-center mb-4 font-extrabold font-lato text-2xl md:text-4xl'
                        />
                    )}
                    {subtitle && (
                        <p className='font-lato flex w-full justify-center text-lg font-medium text-gray-400 md:text-xl'>
                            {subtitle}
                        </p>
                    )}
                </div>
                <div className='grid w-full grid-cols-12'>
                    <div className='col-span-12 mb-8 flex flex-col items-center md:col-span-4 md:mb-0'>
                        <Link href='/map' passHref>
                            <a className='flex w-full flex-col items-center justify-center'>
                                <div className='mb-4 flex h-24 w-24 items-center justify-center rounded-md bg-green-100 text-center md:mb-8'>
                                    <Icon slug='Map' theme='primary' size='lg' />
                                </div>
                                <Heading
                                    text='Find a Brewery'
                                    type='h3'
                                    size='h4'
                                    theme='dark'
                                    customClasses='flex justify-center mb-2 md:mb-4 font-extrabold font-lato text-xl md:text-2xl'
                                />
                                <p className='lg:w-90 medium text-center text-base text-gray-400'>
                                    Search and filter what&apos;s important to you with Breweries
                                    For You
                                </p>
                            </a>
                        </Link>
                    </div>
                    <div className='col-span-12 mb-8 flex flex-col items-center md:col-span-4 md:mb-0'>
                        <Link href='/map?option=brewery-routes'>
                            <a className='flex flex-col items-center'>
                                <div className='mb-4 flex h-24 w-24 items-center justify-center rounded-md bg-blue-100 md:mb-8'>
                                    <Icon slug='Routes' theme='secondary' size='lg' />
                                </div>
                                <Heading
                                    text='Brewery Routes'
                                    type='h3'
                                    size='h4'
                                    theme='dark'
                                    customClasses='flex justify-center mb-2 md:mb-4 font-extrabold font-lato text-xl md:text-2xl'
                                />
                                <p className='lg:w-90medium text-center text-base text-gray-400'>
                                    Explore, create, and share the perfect BreweryRoute
                                </p>
                            </a>
                        </Link>
                    </div>
                    <div className='col-span-12 mb-8 flex flex-col items-center md:col-span-4 md:mb-0'>
                        <Link href='/brewknowledge'>
                            <a className='flex flex-col items-center justify-center'>
                                <div className='mb-4 flex h-24 w-24 items-center justify-center rounded-md bg-yellow-100 md:mb-8'>
                                    <Icon slug='Learn' theme='warning' size='lg' />
                                </div>
                                <Heading
                                    text='Find a Brew'
                                    type='h3'
                                    size='h4'
                                    theme='dark'
                                    customClasses='flex justify-center mb-2 md:mb-4 font-extrabold font-lato text-xl md:text-2xl'
                                />
                                <p className='lg:w-90 medium text-center text-base text-gray-400'>
                                    Explore the world of brews and discover something new.
                                </p>
                            </a>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Columns
