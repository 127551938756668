import React from 'react'
import { Asset, BreweryRoute, BreweryRouteGeoCoord } from 'src/types/schema/graphql'
import { CardImage } from 'src/features/account/components/favorites'
import mapboxgl from 'mapbox-gl'
import useMapFunctions from 'src/features/Map/utils/functions/useMapFunctions'
import {
    addUpdateRouteMarkers,
    drawMapRoute,
    guidGenerator,
} from 'src/features/Map/utils/functions'
import { mapStyle } from '../../../../util/constants'

interface ILargeGridSquare {
    route: BreweryRoute
    assets: Asset[]
}

const LargeGridSquare: React.FC<ILargeGridSquare> = ({ route, assets }) => {
    const accessToken = process.env.NEXT_PUBLIC_MAPBOX_TOKEN
    const mapContainer = React.useRef<mapboxgl.Map>(null)
    const { getRouteGeoJson } = useMapFunctions()
    const [contentLoaded, setContentLoaded] = React.useState<boolean>(false)
    const parsedCoords: BreweryRouteGeoCoord[] = route?.coordinates?.map((coord) => ({
        lat: coord.lat,
        lon: coord.lon,
        title: coord.title,
        placeName: coord?.placeName,
        location: coord?.location,
    }))
    const setMapContainer = React.useCallback(
        (element) => {
            if (element) {
                const map = new mapboxgl.Map({
                    container: element,
                    style: mapStyle,
                    accessToken,
                    minZoom: 4,
                    fitBoundsOptions: { padding: { top: 50, bottom: 20, left: 20, right: 50 } },
                    interactive: false,
                })
                mapContainer.current = map
                mapContainer?.current?.on('load', () => {
                    ;(async () =>
                        await getRouteGeoJson(parsedCoords)
                            .then((json) => {
                                if (json) drawMapRoute(json, mapContainer)
                                addUpdateRouteMarkers(mapContainer?.current, route)
                            })
                            .then(() => setContentLoaded(true))
                            .catch((err) =>
                                console.error('there has been an error in LargeGridSquare.tsx', err)
                            ))()
                })
            }
        },
        [route]
    )
    return route ? (
        <div className='flex flex-col gap-3 lg:flex-row'>
            <div
                className='h-full min-h-[15.25rem] w-full overflow-hidden rounded-xl'
                ref={setMapContainer}
            />
        </div>
    ) : (
        <>
            {assets && (
                <CardImage
                    customClasses='min-h-[15.25rem] flex align-center justify-center  w-full overflow-hidden rounded-xl object-cover'
                    asset={assets[0]}
                    alt={'Location Logo'}
                />
            )}
        </>
    )
}
export default LargeGridSquare
