import { CompositeSearchResult } from '../../types'
import TabOptions from '../../types/enums/TabOptions'

const productTypes = ['Beer', 'Cider', 'Kombucha', 'Mead']

const filterResultsByTab = (
    items: CompositeSearchResult[],
    tab: TabOptions
): CompositeSearchResult[] => {
    if (tab === TabOptions.All) return items
    if (tab === TabOptions.Brand) return items?.filter((item) => item?.__typename === 'Brand')
    if (tab === TabOptions.Location) return items?.filter((item) => item?.__typename === 'Location')
    if (tab === TabOptions.Product)
        return items?.filter((item) => productTypes.includes(item?.__typename))
}

export default filterResultsByTab
